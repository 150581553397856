import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Mainpage from './pages/Mainpage';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <Router>
      <Helmet>
       
      </Helmet>
      <Routes>
        <Route path="/" element={<Mainpage />} />
      </Routes>
    </Router>
  );
}

export default App;
