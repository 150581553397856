import React, { useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Mainpage.css';
import { Navbar, Container, Nav, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faConciergeBell, faParking, faBed, faKitchenSet, faBroom, faPaintBrush, faTools, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';
import lightbox from 'lightbox2/dist/js/lightbox.min.js';
import 'lightbox2/dist/css/lightbox.min.css';
import BrimstonVideo from '../images/lordganesha.mp4';
import icon1 from '../images/location-icon.png';
import icon2 from '../images/phone-call-icon.png';
import logo from '../images/Ganesha.png';
import img1 from '../images/image21.jpg'; 
import img2 from '../images/image10.jpg';
import img3 from '../images/image6.jpg';
import img4 from '../images/carousel1.jpg';
import img5 from '../images/image11.jpg';
import img6 from '../images/image14.jpg';
import img7 from '../images/image7.jpg';
import img8 from '../images/image3.jpg';
import img9 from '../images/image17.jpg';
import img10 from '../images/image6.jpg';
import img11 from '../images/image22.jpg';
import FooterVideo from '../images/footer.mp4';

const Mainpage = () => {
  const navbarToggleRef = useRef(null);

  useEffect(() => {
    lightbox.option({
      resizeDuration: 200,
      wrapAround: true,
    });
  }, []);

  const handleNavLinkClick = () => {
    // Close the Navbar on mobile view
    if (navbarToggleRef.current && window.innerWidth < 992) {
      navbarToggleRef.current.click();
    }
  };

  return (
    <div className="page-container">
      <div className="main-container">
        {/* Video Section */}
        <div className="video-wrapper">
          <video autoPlay loop muted className="w-100">
            <source src={BrimstonVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Navbar */}
        <Navbar expand="lg" sticky="top" className="custom-navbar">
          <Container fluid>
            <Navbar.Brand href="#" className="text-white">
              <img src={logo} alt="Logo" className="d-inline-block align-top logo-image" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarNavAltMarkup" ref={navbarToggleRef} />
            <Navbar.Collapse id="navbarNavAltMarkup" className="justify-content-end">
              <Nav className="navbar-nav">
                <Nav.Link>
                  <Link
                    to="home"
                    smooth={true}
                    duration={500}
                    offset={-80}
                    className="nav-link text-white"
                    onClick={handleNavLinkClick}
                  >
                    Home
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to="facilities"
                    smooth={true}
                    duration={500}
                    offset={-80}
                    className="nav-link text-white"
                    onClick={handleNavLinkClick}
                  >
                    Facilities
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to="gallery"
                    smooth={true}
                    duration={500}
                    offset={-80}
                    className="nav-link text-white"
                    onClick={handleNavLinkClick}
                  >
                    Gallery
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to="testimonial"
                    smooth={true}
                    duration={500}
                    offset={-80}
                    className="nav-link text-white"
                    onClick={handleNavLinkClick}
                  >
                    Testimonial
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to="contact"
                    smooth={true}
                    duration={500}
                    offset={-80}
                    className="nav-link text-white"
                    onClick={handleNavLinkClick}
                  >
                    Contact Us
                  </Link>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* Running Line */}
        <div className="running-line">
          <marquee behavior="scroll" direction="left" className="running-line-text">
            Welcome to our venue! Enjoy our exceptional facilities and make your event unforgettable.
          </marquee>
        </div>

        {/* Main Content */}
        <section id="home" className="section">
          {/* Carousel */}
          <Carousel fade>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img2} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img3} alt="Third slide" />
            </Carousel.Item>
          </Carousel>
        </section>

        {/* Facilities Section */}
        <section id="facilities" className="section facilities">
          <h2 className="underline">Facilities</h2>
          <div className="facility-list">
            <div className="facility">
              <FontAwesomeIcon icon={faBuilding} size="2x" className="facility-icon" />         
              <h3>Hall</h3>
              <p>Celebrate your union at Mushiga AC Hall, featuring timeless marble flooring and elegant ceilings. The second floor accommodates 200 guests, while the third floor, suitable for homam but not weddings, accommodates 120 guests. Our team ensures a flawless event tailored to your preferences, be it traditional or themed.</p>
            </div>
            <div className="facility">
              <FontAwesomeIcon icon={faUtensils} size="2x" className="facility-icon" />
              <h3>Dining</h3>
              <p>The Dining Hall on the second floor accommodates up to 50 guests, ideal for weddings, receptions or birthday celebrations. The third floor, seating 30 guests, is suitable for dining events only, without kitchen facilities. Each floor is tailored to meet diverse event requirements.</p>
            </div>
            <div className="facility">
              <FontAwesomeIcon icon={faParking} size="2x" className="facility-icon" />
              <h3>Parking</h3>
              <p>There are two to three indoor car parking spots available, in addition to open parking facilities and valet parking service.</p>
            </div>
            <div className="facility">
              <FontAwesomeIcon icon={faBed} size="2x" className="facility-icon" />
              <h3>Guest Room</h3>
              <p>Our deluxe A/C rooms with attached bathrooms offer a comfortable and delightful stay. Every detail has been thoughtfully arranged to ensure your comfort and style. Experience a home-like atmosphere when you stay with us.</p>
            </div>
            <div className="facility">
              <FontAwesomeIcon icon={faBroom} size="2x" className="facility-icon" />
              <h3>Cleaning</h3>
              <p>All restrooms feature modern European-style sanitary ware. We uphold the highest hygiene standards with a 24/7 water supply and a dedicated maintenance team. We understand that our hall's reputation relies significantly on the excellent housekeeping skills of our staff.</p>
            </div>
            <div className="facility">
              <FontAwesomeIcon icon={faTools} size="2x" className="facility-icon" />
              <h3>Technical Facilities</h3>
              <p>The lift service has a capacity of 13 passengers and features an automatic device that stops at the nearest floor during power outages or breakdowns. Additionally, we provide technical facilities such as a sound system, microphone, and genset.</p>
            </div>
          </div>
        </section>
        {/* Gallery Section */}
        <section id="gallery" className="section gallery">
          <h2 class="underline">Gallery</h2>
          <div className="gallery-grid">
            <a href={img1} data-lightbox="gallery">
              <img src={img1} alt="Gallery Image 1" className="gallery-image" />
            </a>
            <a href={img2} data-lightbox="gallery">
              <img src={img2} alt="Gallery Image 2" className="gallery-image" />
            </a>
            <a href={img3} data-lightbox="gallery">
              <img src={img3} alt="Gallery Image 3" className="gallery-image" />
            </a>
            <a href={img4} data-lightbox="gallery">
              <img src={img4} alt="Gallery Image 4" className="gallery-image" />
            </a>
            <a href={img5} data-lightbox="gallery">
              <img src={img5} alt="Gallery Image 5" className="gallery-image" />
            </a>
            <a href={img6} data-lightbox="gallery">
              <img src={img6} alt="Gallery Image 6" className="gallery-image" />
            </a>
            <a href={img7} data-lightbox="gallery">
              <img src={img7} alt="Gallery Image 7" className="gallery-image" />
            </a>
            <a href={img8} data-lightbox="gallery">
              <img src={img8} alt="Gallery Image 8" className="gallery-image" />
            </a>
            <a href={img9} data-lightbox="gallery">
              <img src={img9} alt="Gallery Image 9" className="gallery-image" />
            </a>
            <a href={img10} data-lightbox="gallery">
              <img src={img10} alt="Gallery Image 10" className="gallery-image" />
            </a>
            <a href={img11} data-lightbox="gallery">
              <img src={img11} alt="Gallery Image 11" className="gallery-image" />
            </a>
          </div>
        </section>
{/* Testimonials Section */}
{/* Testimonials Section */}
<section id="testimonial" className="section testimonial">
  <h2 className="underline">What Our Clients Say</h2>
  <Carousel>
    <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"The Interaction , coordination, response and promptness with Mr. Arish was exemplary. I appreciate their services and hospitality.
Convenient space at a prime location. Suitable for small gatherings like Engagement, Birthday party, baby shower, Sangeet etc.
I would highly recommend this hall to anyone who is looking for neat and budget friendly mini hall for functions."</p>
              <p className="author">Archana R</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"Nice place with limited parking space. Best for small functions like Birthday party and other small parties. Hall is in second floor with lift facility. Easy approach as it is situated on the roadside of Baratha matha street."</p>
              <p className="author">Amaladhas Cardoza</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"Centrally located. Suitable for  small functions . More parking space monitored by a security personnel will be beneficial"</p>
              <p className="author">Ramachander Swaminathan</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">Good place and small function  and birthday party  and retirement  party are good to celebrate  this place.
Well maintained  and parking  also available.
Near to bus stop"</p>
              <p className="author">Venukumar B R</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"Hall is fantastic for birthday parties, engagement parties etc... With suitable car parking spaces, Well air conditioned, separate dining room and lift facilities."</p>
              <p className="author">Ravi Sivaraman</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"The hall was decent enough to accommodate over 70-100 pax. Car parking is small inside the premises. Whereas, you have ample space on the road side. It has a dedicated hand wash adjacent to the dining area and also has three individual toilets behind the dining area which is convenient.!</p>
              <p className="author">Abishek Rajasekaran</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"Conducted my Son's Ayush homam here. The hall is well maintained, easily accessible from Velachery main road. Good hall to conduct an event with 100-150 guests."</p>
              <p className="author">Vijay Krishnan Anantharaman</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"Good A/C hall with a good seating capacity. The dining hall was well and the place was clean. There is also a lift facility but there isn't enough parking space otherwise everything is perfect.</p>
              <p className="author">Priya Ravi</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
          <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"Mushiga Party hall is best of places in and around Tambaram, The rates are very reasonable and a very great ambience with good seating capacity, Dining capacity. I would highly recommend for any occasion/small gatherings - Birthday parties, Puberty function, Engagement, Corporate meetings ., Neat , Sanitized & has a great look ! Easy reach from main road.
              👍"</p>
              <p className="author">Kalai Srimathi</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>

        <Carousel.Item>
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-6 col-sm-12">
            <div className="card testimonial-card">
             <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"Good place for conducting small functions like birthdays. Dining place is also available. Reasonable rates. Easily accessible.</p>
              <p className="author">Varadhan V</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"Apt place for small gatherings. Air-conditioned and well designed ventilation in dining hall. Interiors are well done."</p>
              <p className="author">Muhammad Salman Ibrahim</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
        <Carousel.Item>
      <div className="row justify-content-center">
      <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="card testimonial-card">
            <div className="card-body">
              <div className="star-rating">
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
                <span className="star">★</span>
              </div>
              <p className="quote">"Product looks good for wall finishes. Madam explained well abt the product. Easy to locate during normal traffic."</p>
              <p className="author">Deepak Thangaprian</p>
            </div>
          </div>
        </div>
        </div>
        </Carousel.Item>
  </Carousel>
</section>

        {/* Contact Section */}
        <section id="contact" className="section contact">
          <div className="contact-container">
            <form action="https://api.web3forms.com/submit"   method="POST" className="contact-left">
              <div className="contact-left-title">
                <h2 class="underline">Contact Us</h2>
                <hr />
              </div>
              <input type="hidden" name="access_key" value="628cd62f-b777-471f-8354-45fb9fa588f4" />
              <input type="text" name="name" placeholder="Your Name" className="contact-inputs" required />
              <input type="email" name="email" placeholder="Your Email" className="contact-inputs" required />
              <textarea name="message" placeholder="Your Message" className="contact-inputs contact-left-textarea" required></textarea>
              <button type="submit">Submit <img src="#" alt="" /></button>
            </form>
            <div className="contact-right">
              <img src={icon1} alt="Contact" />
              <p>&nbsp;&nbsp;&nbsp;&nbsp;158, Bharathamadha St,<br />
              &nbsp;&nbsp;&nbsp;&nbsp;Railway Colony, East Tambaram,<br />
              &nbsp;&nbsp;&nbsp;&nbsp;Tambaram, Chennai,<br />
              &nbsp;&nbsp;&nbsp;&nbsp;Tamil Nadu 600 059.<br />
              </p>
              <img src={icon2} alt="Contact" />
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 7397304999</p>
            </div>
          </div>
        </section>

        {/* Map Section */}
        <section id="map" className="section map">
          <h2 className="underline">Find Us</h2>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.675941904403!2d80.1245666731365!3d12.928537415824833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525f11e22b11a1%3A0x52d28bfc643786df!2s158%2C%20Bharathamadha%20St%2C%20Railway%20Colony%2C%20East%20Tambaram%2C%20Tambaram%2C%20Chennai%2C%20Tamil%20Nadu%20600059!5e0!3m2!1sen!2sin!4v1718785842527!5m2!1sen!2sin"
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </section>

        {/* Footer */}
        <footer>
          <div className="footer-video-container">
            <video autoPlay loop muted className="footer-video">
              <source src={FooterVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Mainpage;